/** @format */
body {
  direction: rtl;
}

.system-direction {
  direction: rtl;
}
img {
  width: 100%;
  max-height: auto;
}
/* for main menu */

.site-menu ul ul ul {
  right: 100%;
}

.site-menu ul li a {
  border-left: solid 1px #5d0324;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700;
}

.site-menu-float ul ul {
  width: 95%;
  padding-right: 5%;
}

html {
  box-sizing: border-box;
  overflow-x: hidden !important;
}

* {
  box-sizing: inherit;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
}

body {
  background: #f0f0f0;
  font-weight: bold;
  margin: 0;
  position: relative;
  font-family: Arial, Helvetica, sans-serif !important;
  overflow-x: hidden !important;
}

.flex-row,
.site,
.form-unit,
.app_option,
.pages ul,
.content-caption,
.site-menu ul {
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-reverse {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-direction: row-reverse;
  /* Safari 6.1+ */
  -webkit-flex-wrap: wrap;
  /* Safari 6.1+ */
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

body a {
  color: #8e003b;
  text-decoration: none;
}

body a:hover {
  color: #d03e2f;
}
.header-wrapper-background {
  background: #000000;
  border-top: 6px solid #8e003b;
}

.header {
  max-width: 1180px;
  margin: auto;
}

.header-logo {
  background: #000000;
  padding: 6px;
  text-align: center;
  position: relative;
}

.header-logo-lc-mc {
  margin-bottom: -48px;
}

.header-logo #image-lc {
  max-width: 96%;
  max-height: 80px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

.header-logo #image-sc {
  max-width: 100%;
  max-height: 80px;
}

.toolbar-option {
  background: #fff;
  padding: 8px 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 2px #969696;
}

.toolbar-option-wrapper {
  max-width: 1180px;
  margin: auto;
}

.toolbar-option-separator {
  height: 10px;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-around;
}
/*  */
/*  */
/*  */

#center {
  max-width: 1180px;
  margin: 10px auto;
}

.footer-wrapper {
  background: #000000;
  padding: 10px 0;
}

footer {
  display: block;
  text-align: center;
  max-width: 1180px;
  margin: auto;
  color: #ffffff;
  padding: 10px;
  line-height: 1.5;
  background: #5d0324;
}

footer a {
  color: #ffffff;
}

footer a:hover {
  color: #ffffff;
  opacity: 0.8;
}

.site-content img {
  max-width: 100%;
}

.typcn-small {
  font-size: 12px !important;
}

.typcn-medium {
  font-size: 18px !important;
}

.typcn-large {
  font-size: 24px !important;
}

input {
  padding: 5px 10px;
  min-height: 40px;
  width: 100%;
  font-weight: bold;
  border: 0;
  box-shadow: inset 0 0 2px 1px #000000;
}

input[type="file"] {
  box-shadow: inset 0 0 0;
}

input[type="url"] {
  direction: ltr;
}

input[type="checkbox"],
input[type="checkbox"]:focus {
  width: 20px;
  min-height: 15px;
  margin-top: 10px;
  box-shadow: inset 0 0 0;
  display: inline-block;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: inset 0 0 2px 1px #27ae60;
}

.socialicon {
  background: url("/public/images/socialicon.png");
  height: 32px;
  width: 32px;
  margin: 0 5px;
  transition: transform 0.6s ease-in-out;
  display: block;
}

.socialicon:hover {
  transform: rotate(360deg);
}

.socialicon-facebook {
  background-position: 0 -224px;
}

.socialicon-google-plus {
  background-position: 0 -192px;
}

.socialicon-whatsapp {
  background-position: 0 -160px;
}

.socialicon-youtube {
  background-position: 0 -128px;
}

.socialicon-twitter {
  background-position: 0 -96px;
}

.socialicon-soundcloud {
  background-position: 0 -64px;
}

.socialicon-linkedin {
  background-position: 0 -32px;
}

.socialicon-instagram {
  background-position: 0 0;
}

/* loading-box */

/* nav menu */

.site-menu {
  background-color: #000000;
}

.site-menu-prevent-overflow-onload {
  max-height: 43px;
  overflow: hidden;
}

.site-menu .nav-home-link {
  background: #8e003b;
  height: 100%;
  min-width: 46px;
  text-align: center;
}

.site-menu .nav-home-link:hover {
  background: #d03e2f;
  box-shadow: 0px 0px 2px #d03e2f;
}

.site-menu .nav-home-icon {
  display: block;
  margin-top: -6px;
  margin-bottom: -2px;
}

.site-menu-has-sub:after {
  content: "\00a0 \2807";
  display: inline-block;
  margin-top: -6px;
}

.site-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  transition: visibility 0s, opacity 0.8s;
}

.site-menu ul:after {
  content: "";
  clear: both;
  display: block;
}

.site-menu ul ul {
  display: block;
  visibility: hidden;
  z-index: 6;
  opacity: 0;
}

.site-menu ul li:hover > ul {
  visibility: visible;
  opacity: 1;
}

.site-menu ul li.hidden {
  display: none;
}

.site-menu ul li.hidden a[rel="more-button"] {
  border: 0;
  text-align: center;
}

.site-menu ul li:hover {
  background: #5d0324;
}

.site-menu ul li:hover a {
  color: #ffffff;
  box-shadow: 0px 0px 2px #000000;
}

.site-menu ul li a {
  display: block;
  padding: 12px 10px 12px 10px;
  color: #ffffff;
  border-left: solid 1px #5d0324;
  min-height: 30px;
}

.site-menu ul ul {
  position: absolute;
  background: #000000;
}

.site-menu ul ul li {
  float: none;
  position: relative;
}

.site-menu ul ul li a {
  min-width: 130px;
}

.site-menu ul ul li a:hover {
  background: #5d0324;
  box-shadow: 0px 0px 2px #000000;
}

.site-menu ul ul ul {
  position: absolute;
  top: 0;
}

/* * site menu for mobile */

.site-menu-float {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  color: #fafafa;
  background-color: rgba(0, 0, 0, 0.78);
  padding: 5%;
  z-index: 101;
  min-height: 100%;
}

.site-menu-float-button {
  cursor: pointer;
  color: #000000;
  padding: 4px 2px 4px 2px;
  margin: auto;
}

.site-menu-float-close-icon {
  margin: 0 0 12px 0;
  display: inline-block;
  cursor: pointer;
}

.site-menu-float ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  transition: visibility 0s, opacity 0.8s;
  background: #000000;
}

.site-menu-float ul:after {
  content: "";
  clear: both;
  display: block;
}

.site-menu-float ul li:hover {
  background: #5d0324;
}

.site-menu-float ul li:hover a {
  color: #ffffff;
}

.site-menu-float ul li a {
  display: block;
  padding: 12px 6px;
  color: #ffffff;
  border-bottom: solid 1px #5d0324;
}

.site-menu-float ul ul {
  background: #000000;
  width: 100%;
}

.site-menu-float ul ul li {
  float: none;
  position: relative;
}

.site-menu-float ul ul li a {
  min-width: 100px;
}

/* back to top */

a.back-to-top {
  display: none;
  width: 30px;
  height: 30px;
  position: fixed;
  z-index: 3;
  right: 20px;
  bottom: 60px;
  background: #5d0324;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 0px 1px #ffffff;
  transition: transform 0.4s ease-in-out;
}

a.back-to-top:hover {
  color: #ffffff;
  opacity: 0.9;
  transform: scale(1.1);
}

/* for 404 and closed message */

.website-status-closed,
.website-status-404 {
  background: #fafafa;
  overflow: hidden;
  max-width: 600px;
  margin: 20px auto;
  padding: 10px;
  box-shadow: 0px 0px 2px #969696;
  line-height: 1.5;
  text-align: center;
}

/* responsive system */

/* Custom, iPhone Retina */

@media only screen and (min-width: 1px) and (max-width: 479px) {
  .sc-show {
    display: block;
  }
  .sc-hide {
    display: none;
  }

  .sc-10 {
    width: 8.33333333%;
  }

  .sc-110 {
    width: 91.66666667%;
  }

  .sc-120 {
    width: 100%;
  }
  #center {
    margin: 10px 5px;
  }
}

/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) and (max-width: 1023px) {
  .mc-show {
    display: block;
  }
  .mc-hide {
    display: none;
  }
  /* .generate-mc(@n, @i:1) when (@i =< @n){.mc-@{i}{width:(@i * 100% / @n);} .generate-mc(@n, (@i + 1));} .generate-mc(120);*/

  .mc-35 {
    width: 29.16666667%;
  }

  .mc-40 {
    width: 33.33333333%;
  }

  .mc-80 {
    width: 66.66666667%;
  }

  .mc-85 {
    width: 70.83333333%;
  }

  #center {
    margin: 10px 5px;
  }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 1024px) {
  .lc-show {
    display: block;
  }
  .lc-hide {
    display: none;
  }
  /* .generate-lc(@n, @i:1) when (@i =< @n){.lc-@{i}{width:(@i * 100% / @n);} .generate-lc(@n, (@i + 1));} .generate-lc(120);*/

  .lc-25 {
    width: 20.83333333%;
  }

  .lc-40 {
    width: 33.33333333%;
  }

  .lc-80 {
    width: 66.66666667%;
  }

  .lc-95 {
    width: 79.16666667%;
  }

  .lc-112 {
    width: 93.33333333%;
  }
}

@media print {
  body {
    background: #fff;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
  #center {
    margin: 0;
    padding: 0;
    box-shadow: 0;
  }
}

.footer-wrapper {
  margin-bottom: 42px;
}

.search-content h1 {
  padding: 0;
  margin: 2px;
  font-size: 18px;
}

.search-content p {
  padding: 0;
  margin: 2px;
}

.search-form {
  width: 100%;
  background: #f0f0f0;
  padding: 7px;
  box-shadow: inset 0 0 0 0;
  border: 1px solid transparent;
  min-height: auto;
}

.search-form:focus {
  box-shadow: inset 0 0 0 0;
  border: 1px solid #969696;
}

.autocomplete-suggestions {
  cursor: default;
  border: 1px solid #ccc;
  border-top: 0;
  background: #fff;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  /* core styles should not be changed */
  position: absolute;
  display: none;
  z-index: 9999;
  max-height: 254px;
  overflow: hidden;
  overflow-y: auto;
  box-sizing: border-box;
}

.autocomplete-suggestion {
  position: relative;
  padding: 0 0.6em;
  line-height: 23px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.02em;
  color: #333;
}

.autocomplete-suggestion b {
  color: #239e57;
}

.autocomplete-suggestion.selected {
  background: #f0f0f0;
}
